import React from 'react';
import { Card } from 'react-bootstrap';

interface IKioskGeneralInfo {
  locationAlias: string;
  firstOrderDate: string;
  daysInOperation: number;
  kioskLocation: string;
  nickname: string;
  transactions: number;
  sales: number;
  waterLitres: number;
}

const KioskGeneralInfo: React.FC<IKioskGeneralInfo> = ({
  firstOrderDate,
  daysInOperation,
  locationAlias,
  kioskLocation,
  nickname,
  transactions,
  sales,
  waterLitres,
}) => {
  const _formatDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };
  return (
    <>
      <Card.Text className='col s6 l3 m3'>
        <strong>Oldest Order Date:&nbsp;</strong>
        {_formatDate(firstOrderDate)}
      </Card.Text>
      <Card.Text className='col s6 l3 m3'>
        <strong>Days in Operation:&nbsp;</strong>
        {daysInOperation}
      </Card.Text>

      <Card.Text className='col s12 l6 m6'>
        <strong>Location:&nbsp;</strong>
        {kioskLocation}&nbsp;({locationAlias})
      </Card.Text>
      <Card.Text className='col s6 l3 m3'>
        <strong>Alias:&nbsp;</strong>
        {nickname}
      </Card.Text>
      <Card.Text className='col s6 l3 m3'>
        <strong>Total Transactions:&nbsp;</strong>
        {transactions}
      </Card.Text>
      <Card.Text className='col s6 l3 m3'>
        <strong>Total Sales:&nbsp;</strong>${sales.toFixed(2)}
      </Card.Text>
      <Card.Text className='col s6 l3 m3'>
        <strong>Total Water Dispensed:&nbsp;</strong>
        {waterLitres.toFixed(2)} liters
      </Card.Text>
    </>
  );
};

export default KioskGeneralInfo;
