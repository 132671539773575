import { BottlePricing, RefillPricing } from '../../../../common/Kiosk/KioskSettings';

type FieldConfig<T> = {
  key: keyof T;
  required: boolean;
  type: 'string' | 'number';
};

export const RefillPricingFieldConfig: FieldConfig<RefillPricing>[] = [
  { key: 'caffeine', required: true, type: 'number' },
  { key: 'flavor', required: true, type: 'number' },
  { key: 'minimumCharge', required: true, type: 'number' },
  { key: 'preAuthCharge', required: true, type: 'number' },
];

export const BottlePricingFieldConfig: FieldConfig<BottlePricing>[] = [
  { key: 'base', required: true, type: 'number' },
  { key: 'caffeine', required: true, type: 'number' },
  { key: 'flavor', required: false, type: 'number' },
];

