import React from 'react';
import { Typography } from '@material-ui/core';
import ResolutionBadge from '../ResolutionBadge';
import IncidentReportStrings from '../IncidentReportStrings';
import IncidentResolutionStatus from '../IncidentResolutionStatus';
import BackButton from '../../../../components/Button/BackButton';

interface IIncidentReportHeader {
  resolution?: IncidentResolutionStatus;
}

const IncidentReportHeader: React.FC<IIncidentReportHeader> = ({ resolution }) => {
  return (
    <div
      style={{
        marginBottom: '15px',
        display: 'flex',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        gap: 10,
        width: '100%'
      }}
    >
      <div
        style={{
          marginBottom: '15px',
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          flex: 1
        }}
      >
        <Typography
          style={{
            fontWeight: 400,
            fontSize: '28px',
            font: "'Open Sans', sans-serif",
            paddingRight: '0px',
            paddingLeft: '0px'
          }}
        >
          {IncidentReportStrings.Heading}
        </Typography>
        {resolution && <ResolutionBadge resolution={resolution} />}
        <BackButton />
      </div>
    </div>
  );
};

export default IncidentReportHeader;
