import { Card } from 'react-bootstrap';
import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import KioskEditStrings from '../../KioskEditStrings';
import KioskMonitorReport from '../../../../../hooks/useDeviceMonitor/KioskMonitorReport';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import stringWithSpacesUppercase from '../../../../../utils/stringWithSpacesUppercase';
import BackgroundServices from '../../../../../common/Kiosk/BackgroundServices';

const KioskBackgroundServicesEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const services = kioskSettings?.state.settings?.backgroundServices;

  const updateServiceEnabledState = (key: keyof BackgroundServices, enabled: boolean) => {
    if (!services) return;
    const currentState = services[key];
    kioskSettings?.handleSettingsChange('backgroundServices', {
      ...services,
      [key]: {
        ...currentState,
        enabled,
      },
    });
  };

  const message = (deviceStatus?: KioskMonitorReport, serviceSettings?: BackgroundServices) => {
    if (deviceStatus?.loaded && !deviceStatus.online) {
      return `${KioskEditStrings.KioskOffline}, cannot edit ${KioskEditStrings.BackgroundServicesSettingsLabel}`;
    }
    if (deviceStatus?.ready && !serviceSettings) {
      return `No ${KioskEditStrings.BackgroundServicesSettingsLabel} retrieved from kiosk`;
    }
  };

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.BackgroundServicesSettingsLabel}
      </Card.Subtitle>
      <p>{message(kioskSettings?.deviceStatus, services)}</p>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        {services &&
          Object.keys(services).map((service, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={services?.[service as keyof BackgroundServices].enabled}
                  onChange={(e) =>
                    updateServiceEnabledState(service as keyof BackgroundServices, e.target.checked)
                  }
                  name={service}
                />
              }
              label={stringWithSpacesUppercase(service)}
              key={`${service}-${index}`}
              style={{ color: '#333' }}
              disabled={!kioskSettings?.deviceStatus.ready}
            />
          ))}
      </div>
    </>
  );
};

export default KioskBackgroundServicesEdit;
