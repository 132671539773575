import { TextField } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import { BottlePricing, RefillPricing } from '../../../../common/Kiosk/KioskSettings';
import { BottlePricingFieldConfig, RefillPricingFieldConfig } from './KioskSettingsFieldConfig';
import formatFieldName from '../../../../utils/formatFieldName';
import { useContext } from 'react';
import { KioskSettingsContext } from './KioskSettingsContext';
import KioskEditStrings from '../KioskEditStrings';

const KioskDrinkPricingEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const defaultBottlePricing: BottlePricing = {
    base: 3,
    caffeine: 0.15,
    flavor: 0.25,
  };

  const defaultRefillPricing: RefillPricing = {
    base: 0,
    caffeine: 0.15,
    flavor: 0.99,
    preAuthCharge: 5,
    minimumCharge: 0.15,
  };

  const updateBottlePricing = (key: keyof BottlePricing, value: number) => {
    const currentPricing = kioskSettings?.state.settings?.bottlePricing || defaultBottlePricing;
    kioskSettings?.handleSettingsChange('bottlePricing', {
      ...currentPricing,
      [key]: value,
    });
  };

  const updateRefillPricing = (key: keyof RefillPricing, value: number) => {
    const currentPricing = kioskSettings?.state.settings?.refillPricing || defaultRefillPricing;
    kioskSettings?.handleSettingsChange('refillPricing', {
      ...currentPricing,
      [key]: value,
    });
  };

  const handleDecimalInputChange = (value: string) => Number(parseFloat(value).toFixed(2));

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.BottlePricingLabel}
      </Card.Subtitle>
      <div>
        {BottlePricingFieldConfig.map((field) => (
          <div key={field.key} className='col s12 m4'>
            <TextField
              size='small'
              variant='outlined'
              fullWidth={true}
              required={field.required}
              id={`bottle-pricing-${field.key}`}
              label={formatFieldName(field.key)}
              value={kioskSettings?.state?.settings?.bottlePricing?.[field.key] || 0}
              onChange={(e) => updateBottlePricing(field.key, handleDecimalInputChange(e.target.value))}
              style={{ margin: '10px 0' }}
              inputProps={{
                step: '0.01',
              }}
              type={field.type}
              disabled={!kioskSettings?.deviceStatus.ready}
            />
          </div>
        ))}
      </div>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.RefillPricingLabel}
      </Card.Subtitle>
      {RefillPricingFieldConfig.map((field) => (
        <div key={field.key} className='col s12 m4'>
          <TextField
            size='small'
            variant='outlined'
            fullWidth={true}
            required={field.required}
            id={`refill-pricing-${field.key}`}
            label={formatFieldName(field.key)}
            value={kioskSettings?.state?.settings?.refillPricing?.[field.key] || 0}
            onChange={(e) => updateRefillPricing(field.key, handleDecimalInputChange(e.target.value))}
            style={{ margin: '10px 0' }}
            inputProps={{
              step: '0.01',
            }}
            type={field.type}
            disabled={!kioskSettings?.deviceStatus.ready}
          />
        </div>
      ))}
    </>
  );
};

export default KioskDrinkPricingEdit;
