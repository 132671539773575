import { MenuItem, TextField } from '@material-ui/core';
import KioskCommandSequence from '../../../../../common/Kiosk/KioskCommandSequence';
import { useState } from 'react';
import stringWithSpacesUppercase from '../../../../../utils/stringWithSpacesUppercase';

interface IKioskCommandSequenceSelect {
  disabled: boolean;
  onChange: (sequence: KioskCommandSequence) => unknown;
}

const KioskCommandSequenceSelect: React.FC<IKioskCommandSequenceSelect> = ({ disabled, onChange }) => {
  const [sequence, setSequence] = useState<KioskCommandSequence>();

  const handleSequenceChange = (sequence: KioskCommandSequence) => {
    setSequence(sequence);
    onChange(sequence);
  };

  return (
    <>
      <TextField
        select
        type='select'
        size='small'
        variant='outlined'
        fullWidth={true}
        required={true}
        id='kioskCommandSequence'
        label='Kiosk Command Sequence'
        value={sequence}
        onChange={(e) => handleSequenceChange(e.target.value as KioskCommandSequence)}
        style={{ margin: '20px 0' }}
        disabled={disabled}
      >
        {Object.entries(KioskCommandSequence).map(([key, value]) => (
          <MenuItem key={key} value={value}>
            {stringWithSpacesUppercase(key)}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default KioskCommandSequenceSelect;
