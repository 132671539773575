import { Card } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { KioskSettingsContext } from '../Settings/KioskSettingsContext';
import KioskSettingsStatusMessage from '../Settings/KioskSettingsStatusMessage';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Features } from '../../../../common/Kiosk/KioskSettings';
import stringWithSpacesUppercase from '../../../../utils/stringWithSpacesUppercase';
import KioskService from '../../../../services/Kiosk/KioskService';
import { useAppSelector } from '../../../../hooks/storeHooks';
import KioskCommand from '../../../../services/Kiosk/KioskCommand';
import IconLoading from '../../../../components/ui/Icons/IconLoading';
import KioskEditStrings from '../KioskEditStrings';

const KioskFeaturesEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  const user = useAppSelector((store) => store);
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<Error>();
  const [toggleInProgress, setToggleInProgress] = useState<boolean>(false);

  const features = kioskSettings?.state.settings?.features;

  const handleFeatureChange = async (feature: keyof Features, enable: boolean) => {
    try {
      setToggleInProgress(true);
      setMessage(undefined);
      setError(undefined);
      const toggleResponse = await new KioskService(kioskSettings?.state.kioskId).sendToggleFeatureCommand(
        KioskCommand.ToggleFeature,
        enable,
        user.auth.user.uuid,
        feature
      );
      if (toggleResponse.error) throw new Error(toggleResponse.error);
      setMessage(toggleResponse.commandResponse.status);
      kioskSettings?.handleSettingsChange('features', toggleResponse.commandResponse.features);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(JSON.stringify(err)));
    } finally {
      setToggleInProgress(false);
    }
  };

  return (
    <Card style={{ padding: '2%' }}>
      <Card.Body>
        <Card.Title>Features</Card.Title>
        <KioskSettingsStatusMessage deviceStatus={kioskSettings?.deviceStatus} />
        <Card.Body className='row' style={{ display: 'flex', flexDirection: 'column' }}>
          {features &&
            Object.keys(features).map((feature, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={kioskSettings?.state.settings?.features?.[feature as keyof Features]}
                    onChange={(e) => handleFeatureChange(feature as keyof Features, e.target.checked)}
                    name={feature}
                  />
                }
                label={stringWithSpacesUppercase(feature)}
                key={`${feature}-${index}`}
                style={{ color: '#333' }}
                disabled={!kioskSettings?.deviceStatus.ready || toggleInProgress}
              />
            ))}
        </Card.Body>
        <span style={{ textTransform: 'capitalize' }}>{message}</span>
        {toggleInProgress && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <IconLoading width={50} height={20} /> {KioskEditStrings.FeatureToggleInProgress}
          </div>
        )}
        <span style={{ color: 'red' }}>{error?.message}</span>
      </Card.Body>
    </Card>
  );
};
export default KioskFeaturesEdit;
