export enum GantryDevice {
  Gripper = 0,
  GantryX = 1,
  GantryZ = 2,
}

export enum GantryPosition {
  GripperOpen = 0,
  GripperClosed = 1,
  GripperClosedOnBottle = 2,
  GripperBottleSense = 3,
  JogToPosition = 4,
  JogInDirection = 5,
  Unassigned = 255,
}
