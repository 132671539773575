import { Card } from 'react-bootstrap';
import { TowerId, TowerSettings } from '../../../../../common/Kiosk/KioskSettings';
import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import KioskEditStrings from '../../KioskEditStrings';
import KioskTowerEditCard from './KioskTowerEditCard';
import KioskMonitorReport from '../../../../../hooks/useDeviceMonitor/KioskMonitorReport';

const KioskTowersEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const updateTower = <T extends keyof TowerSettings>(field: T, towerId: TowerId, value: TowerSettings[T]) => {
    kioskSettings?.handleTowerChange(field, towerId, value);
  };

  const message = (deviceStatus?: KioskMonitorReport, towers?: TowerSettings[]) => {
    if (deviceStatus?.loaded && !deviceStatus.online) {
      return `${KioskEditStrings.KioskOffline}, cannot edit ${KioskEditStrings.TowersLabel}`;
    }
    if (deviceStatus?.ready && towers?.length === 0) {
      return `No ${KioskEditStrings.TowersLabel} retrieved from kiosk`;
    }
  };

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.TowersLabel}
      </Card.Subtitle>
      <div>
        {message(kioskSettings?.deviceStatus, kioskSettings?.state.settings?.towers)}
        {kioskSettings?.state.settings?.towers &&
          kioskSettings?.state.settings?.towers.map((tower) => (
            <div key={tower.id} className='col s12 m6 l4'>
              <KioskTowerEditCard
                tower={tower}
                onInventoryChange={(tower) => updateTower('inventory', tower.id, tower.inventory)}
                onEnabledChange={(tower) => updateTower('enabled', tower.id, tower.enabled)}
                onActiveChange={(tower) => kioskSettings.handleActivateTower(tower.id, tower.active)}
                disabled={!kioskSettings?.deviceStatus.ready}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default KioskTowersEdit;
