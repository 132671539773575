import { createContext } from 'react';
import KioskSettingsState from '../KioskEditState';
import KioskSettings, { Pump, TowerId, TowerSettings } from '../../../../common/Kiosk/KioskSettings';
import KioskEditTabReference from '../KioskEditTabReference';
import KioskMonitorReport from '../../../../hooks/useDeviceMonitor/KioskMonitorReport';

export interface IKioskSettingsContext {
  state: KioskSettingsState;
  handleSettingsChange: <T extends keyof KioskSettings>(field: T, value: KioskSettings[T]) => void;
  saveSettings: () => Promise<void>;
  switchTab: (tab: KioskEditTabReference) => void;
  deviceStatus: KioskMonitorReport;
  handlePumpChange: <T extends keyof Pump>(field: T, pumpId: number, value: Pump[T]) => void;
  handleTowerChange: <T extends keyof TowerSettings>(
    field: T,
    towerId: TowerId,
    value: TowerSettings[T]
  ) => void;
  handleActivateTower: (towerId: TowerId, activate: boolean) => void;
}

export const KioskSettingsContext = createContext<IKioskSettingsContext | undefined>(undefined);
