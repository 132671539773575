import IncidentReportData from '../../screens/Reports/IncidentReport/IncidentReportData';
import IncidentReportDataExcerpt from '../../screens/Reports/IncidentReport/IncidentReportDataExcerpt';
import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import IKioskHealthReport from './IKiosksHealthReport';

export default class ReportsService {
  static getKiosksHealthReport = async (): Promise<IKioskHealthReport[]> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const report = await apiService.request('reports/kiosk-health', 'GET');
    return report as unknown as IKioskHealthReport[];
  };

  static getIncidentReport = async (correlationId: string): Promise<IncidentReportData> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const report = await apiService.request(`reports/incidents/${correlationId}`, 'GET');
    return report as unknown as any;
  };

  static getIncidentReports = async (): Promise<IncidentReportDataExcerpt[]> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const report = await apiService.request('reports/incidents', 'GET');
    return report as unknown as any[];
  };

  static updateIncidentReport = async (
    correlationId: string,
    updates: Partial<IncidentReportData>
  ): Promise<IncidentReportData> => {
    const apiService = new ApiService(ConfigService.apiHost);
    const updatedReport = await apiService.request(`reports/incidents/${correlationId}`, 'PUT', undefined, updates);
    return updatedReport as unknown as IncidentReportData;
  };
}
