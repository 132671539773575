import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import M from 'materialize-css';
import icn_compostable from '../../images/icn-compostable.svg';
import icn_refill from '../../images/icn-refill.svg';
import icn_payment from '../../images/card-machine.svg';
import KioskOnlineStatus from './KioskOnlineStatus';
import KioskGeneralInfo from './KioskGeneralInfo';
import KioskGraphs from './KioskGraphs';
import FeatureToggle from './FeatureToggle';
import KioskService from '../../services/Kiosk/KioskService';
import { useAppSelector } from '../../hooks/storeHooks';
import initialState from './InitialKioskState';
import useDeviceMonitor from '../../hooks/useDeviceMonitor/useDeviceMonitor';
import IKiosk from '../../services/Kiosk/IKiosk';
import KioskCommand from '../../services/Kiosk/KioskCommand';
import KioskSoftwareUpdateControl from './KioskSoftwareUpdateControl';
import KioskScreenshotControl from './KioskScreenshotControl';
import UserRole from '../../common/UserRole';
import AuthGuard from '../../components/auth/AuthGuard';
import KioskRebootControl from './KioskRebootControl';
import KioskCameraSnapshotControl from './KioskCameraSnapshotControl';
import KioskControlButton from './KioskControlButton';
import KioskStrings from './KioskStrings';

const Kiosk = () => {
  const [state, setState] = useState(initialState);
  const user = useAppSelector((store) => store);
  const params = useParams();
  const deviceStatus = useDeviceMonitor(state.resetCommandActive, state.kiosk?.kiosk?.kiosk_ID);
  const history = useHistory();

  const getKioskData = async (kioskId: string) => {
    try {
      setState({
        ...initialState,
        kiosk: await new KioskService(kioskId).getAllDetails()
      });
    } catch (error) {
      setState({
        ...initialState,
        errorMessage: error instanceof Error ? error.message : JSON.stringify(error)
      });
    }
  };

  useEffect(() => {
    if (!params?.uuid || !user.auth.user.uuid) return;
    getKioskData(params.uuid);
  }, [params, user]);

  const sendResetCommand = async (kiosk: IKiosk) => {
    if (!kiosk?.kiosk_ID) {
      console.error('cannot reset without kiosk_ID');
      return;
    }
    try {
      const restartResponse = await new KioskService(kiosk?.kiosk_ID).sendResetCommand();
      if (restartResponse.error) {
        throw Error(
          `Sending ${KioskCommand.Reset} command to kiosk ${kiosk.kiosk_ID} failed: ${restartResponse.error}`
        );
      }
      M.toast({
        html: `Successfully sent ${KioskCommand.Reset} command to kiosk ${kiosk.kiosk_ID}`,
        classes: 'rounded',
        displayLength: 10000
      });
      setState({ ...state, resetCommandActive: true });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
      M.toast({
        html: errorMessage,
        classes: 'rounded',
        displayLength: 40000
      });
      console.error(error);
    } finally {
      setState({ ...state, resetCommandActive: false });
    }
  };

  const sendSoftwareUpdateRequest = async (kiosk: IKiosk, ignoreMaintenance: boolean) => {
    try {
      setState({ ...state, softwareUpdateActive: true });
      const updateResponse = await new KioskService(kiosk?.kiosk_ID).sendSoftwareUpdateRequest(ignoreMaintenance);
      if (updateResponse.error) {
        throw Error(`Sending software update request to kiosk ${kiosk.kiosk_ID} failed: ${updateResponse.error}`);
      }
      M.toast({
        html: updateResponse.message,
        classes: 'rounded',
        displayLength: 10000
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
      M.toast({
        html: errorMessage,
        classes: 'rounded',
        displayLength: 40000
      });
      console.error(error);
    } finally {
      setState({ ...state, softwareUpdateActive: false });
    }
  };

  const getScreenshotFromKiosk = async (kiosk: IKiosk) => {
    try {
      setState({ ...state, screenshotRequestActive: true });
      const screenshotResponse = await new KioskService(kiosk?.kiosk_ID).sendScreenshotRequest();
      if (screenshotResponse.error) {
        throw Error(`Sending screenshot request to kiosk ${kiosk.kiosk_ID} failed: ${screenshotResponse.error}`);
      }
      setState({ ...state, screenshotURL: screenshotResponse.downloadLink, screenshotRequestActive: false });
    } catch (error) {
      setState({ ...state, screenshotRequestActive: false });
      const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
      M.toast({
        html: errorMessage,
        classes: 'rounded',
        displayLength: 40000
      });
      console.error(error);
    }
  };

  const getCameraSnapshotFromKiosk = async (kiosk: IKiosk) => {
    try {
      setState({ ...state, cameraSnapshotRequestActive: true });
      const snapshotResponse = await new KioskService(kiosk?.kiosk_ID).sendCameraSnapshotRequest();
      if (snapshotResponse.error) {
        throw Error(`Sending cameraSnapshotRequest to kiosk ${kiosk.kiosk_ID} failed: ${snapshotResponse.error}`);
      }
      setState({ ...state, cameraSnapshotURL: snapshotResponse.downloadLink, cameraSnapshotRequestActive: false });
    } catch (error) {
      setState({ ...state, cameraSnapshotRequestActive: false });
      const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
      M.toast({
        html: errorMessage,
        classes: 'rounded',
        displayLength: 40000
      });
      console.error(error);
    }
  };

  const sendToggleFeatureCommand = async (command: KioskCommand, expectedFeatureState: boolean, kiosk: IKiosk) => {
    if (!KioskCommand[command] || !command) {
      console.error('Empty command string from state');
      return;
    }
    if (!state.kiosk?.kiosk?.kiosk_ID) {
      console.error('cannot toggle featured without kiosk_ID');
      return;
    }
    const moduleStatusMap = {
      Compostable: 'dropBottleStatus',
      Refill: 'refillStatus',
      Payment: 'paymentStatus'
    };
    try {
      const toggleResponse = await new KioskService(state.kiosk?.kiosk?.kiosk_ID).sendToggleFeatureCommand(
        command,
        expectedFeatureState,
        user.auth.user.uuid
      );
      if (toggleResponse.error) throw Error(toggleResponse.error);
      M.toast({
        html: `Successfully toggled ${command} ${
          toggleResponse.commandResponse.moduleStatues[moduleStatusMap[command]]
        }, kiosk: ${kiosk.kiosk_ID}`,
        classes: 'rounded',
        displayLength: 10000
      });
      setState({
        ...state,
        loaded: {
          ...state.loaded,
          [command]: true
        },
        kiosk: {
          ...state.kiosk,
          moduleStatus: {
            ...state.kiosk.moduleStatus,
            [moduleStatusMap[command]]: toggleResponse.commandResponse.moduleStatues[moduleStatusMap[command]]
          }
        }
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
      M.toast({
        html: `${command} error: ${errorMessage}`,
        classes: 'rounded',
        displayLength: 40000
      });
      console.error(error);
      setState({
        ...state,
        loaded: {
          ...state.loaded,
          [command]: true
        },
        kiosk: {
          ...state.kiosk,
          moduleStatus: {
            ...state.kiosk.moduleStatus,
            [moduleStatusMap[command]]: expectedFeatureState ? 'Off' : 'On'
          }
        }
      });
    }
  };

  useEffect(() => {
    if (!state.kiosk?.moduleStatus) return;
    setState((prevState) => ({
      ...prevState,
      loaded: {
        ...prevState.loaded,
        Payment: prevState.kiosk?.moduleStatus?.paymentStatus !== undefined,
        Refill: prevState.kiosk?.moduleStatus?.refillStatus !== undefined,
        Compostable: prevState.kiosk?.moduleStatus?.dropBottleStatus !== undefined
      }
    }));
  }, [state.kiosk?.moduleStatus]);

  const _handleFeatureToggle = (feature: KioskCommand, enable: boolean, kiosk: IKiosk) => {
    setState({
      ...state,
      loaded: {
        ...state.loaded,
        [feature]: false
      }
    });
    sendToggleFeatureCommand(feature, enable, kiosk);
  };

  if (!state.kiosk || !state.kiosk?.inventory || !state.kiosk?.drinkPrice) {
    return (
      <div>
        {state.errorMessage}
        <ReactLoading
          type={'spinningBubbles'}
          color={'#58cbe1'}
          height='20%'
          width='20%'
          className='center-align dash-progress-spinner'
        />
      </div>
    );
  }

  return (
    state.kiosk && (
      <div className='valign-wrapper  animate__animated animate__slideInLeft'>
        {state.errorMessage}
        <div className='row'>
          <Card style={{ width: '100%', borderRadius: '20px' }} className='col s12 l12 m12 filter-card'>
            <Card.Title style={{ fontWeight: '400', marginTop: '2%', marginBottom: '-1%' }}>
              <div className='col s6 l6 m6'>
                <strong>{state.kiosk?.kiosk?.kiosk_ID}</strong>
              </div>
              <KioskOnlineStatus online={deviceStatus.online} loaded={deviceStatus.loaded} />
            </Card.Title>
            <Card.Body>
              <Card.Body className='s12 l12 m12 right-align'>
                <div className='col s12'>
                  <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.auth.user?.role}>
                    <KioskControlButton
                      display
                      disabled={false}
                      onClick={() => {
                        history.push(`${state.kiosk?.kiosk?.kiosk_ID}/configure`);
                      }}
                      label={KioskStrings.KioskConfigureScreenButton}
                      color='cyan'
                    />
                  </AuthGuard>
                  <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.auth.user?.role}>
                    <KioskScreenshotControl
                      display
                      disabled={!deviceStatus.loaded || !deviceStatus.online}
                      onRequest={() => {
                        setState({ ...state, screenshotURL: undefined });
                        getScreenshotFromKiosk(state.kiosk?.kiosk as IKiosk);
                      }}
                      onClose={() => setState({ ...state, screenshotURL: undefined })}
                      imageUrl={state.screenshotURL}
                    />
                  </AuthGuard>
                  <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.auth.user?.role}>
                    <KioskCameraSnapshotControl
                      display
                      disabled={!deviceStatus.loaded || !deviceStatus.online}
                      onRequest={() => {
                        setState({ ...state, cameraSnapshotURL: undefined });
                        getCameraSnapshotFromKiosk(state.kiosk?.kiosk as IKiosk);
                      }}
                      onClose={() => setState({ ...state, cameraSnapshotURL: undefined })}
                      imageUrl={state.cameraSnapshotURL}
                    />
                  </AuthGuard>
                  <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.auth.user?.role}>
                    <KioskSoftwareUpdateControl
                      display
                      disabled={!deviceStatus.loaded || !deviceStatus.online || state.softwareUpdateActive}
                      onConfirm={(ignoreMaintenance: boolean) =>
                        sendSoftwareUpdateRequest(state.kiosk?.kiosk as IKiosk, ignoreMaintenance)
                      }
                    />
                  </AuthGuard>
                  <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.auth.user?.role}>
                    <KioskRebootControl
                      display
                      disabled={!deviceStatus.loaded || !deviceStatus.online || state.resetCommandActive}
                      onConfirm={() => sendResetCommand(state.kiosk?.kiosk as IKiosk)}
                    />
                  </AuthGuard>
                </div>
              </Card.Body>
              {state.kiosk.kiosk && (
                <KioskGeneralInfo
                  kioskLocation={state.kiosk.kiosk.location}
                  locationAlias={state.kiosk.kiosk.locationAlias}
                  firstOrderDate={state.kiosk.firstOrderDate}
                  nickname={state.kiosk.kiosk.nickname}
                  daysInOperation={state.kiosk.daysInOperation}
                  transactions={state.kiosk.transactions}
                  sales={state.kiosk.sales}
                  waterLitres={state.kiosk.waterLitres}
                />
              )}
              <Card.Text className='col s12 m6 l6'>
                <FeatureToggle
                  disabled={Object.values(state.loaded).includes(false) || !deviceStatus.online || !deviceStatus.loaded}
                  loading={!state.loaded.Compostable}
                  icon={icn_compostable}
                  status={state.kiosk.moduleStatus.dropBottleStatus === 'On'}
                  onFeatureToggle={(currentState) => {
                    _handleFeatureToggle(KioskCommand.Compostable, !currentState, state?.kiosk?.kiosk as IKiosk);
                  }}
                  details={[
                    ['Transactions', state.kiosk.compostableTransactions],
                    ['Sales', `$${state.kiosk.compostableSales.toFixed(2)}`],
                    ['Transactions/Day', `${state.kiosk.averageCompostableTransactions.toFixed(2)}`],
                    ['Sales/Day', `$${state.kiosk.averageCompostableSales.toFixed(2)}`]
                  ]}
                  authorizedRoles={[UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin, UserRole.MachineAdmin]}
                  userRole={user?.auth.user.role}
                />
                <FeatureToggle
                  disabled={Object.values(state.loaded).includes(false) || !deviceStatus.online || !deviceStatus.loaded}
                  loading={!state.loaded.Refill}
                  icon={icn_refill}
                  status={state.kiosk.moduleStatus.refillStatus === 'On'}
                  onFeatureToggle={(currentState) => {
                    _handleFeatureToggle(KioskCommand.Refill, !currentState, state?.kiosk?.kiosk as IKiosk);
                  }}
                  details={[
                    ['Transactions', state.kiosk.refillTransactions],
                    ['Sales', `$${state.kiosk.refillSales.toFixed(2)}`],
                    ['Transactions/Day', `${state.kiosk.averageRefillTransactions.toFixed(2)}`],
                    ['Sales/Day', `$${state.kiosk.averageRefillSales.toFixed(2)}`],
                    ['Paid Transactions', state.kiosk.refillPaidTransactions]
                  ]}
                  authorizedRoles={[UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin, UserRole.MachineAdmin]}
                  userRole={user?.auth.user.role}
                />
                <FeatureToggle
                  disabled={Object.values(state.loaded).includes(false) || !deviceStatus.online || !deviceStatus.loaded}
                  loading={!state.loaded.Payment}
                  icon={icn_payment}
                  status={state.kiosk.moduleStatus.paymentStatus === 'On'}
                  onFeatureToggle={(currentState) => {
                    _handleFeatureToggle(KioskCommand.Payment, !currentState, state?.kiosk?.kiosk as IKiosk);
                  }}
                  details={[]}
                  authorizedRoles={[UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin, UserRole.MachineAdmin]}
                  userRole={user?.auth.user.role}
                />
              </Card.Text>
              {state.kiosk.inventory && (
                <Card.Body className='col s6 l3 m3'>
                  <table style={{ tableLayout: 'fixed' }}>
                    <tr className='inventory-summary'>
                      <th className='left-align inventory-summary-header'>Active Tower</th>
                      <td className='center-align'>{state.kiosk.inventory.towerSelected}</td>
                    </tr>
                    <tr>
                      <th className='left-align inventory-summary-header'>Tower 1</th>
                      <td className='center-align'>{state.kiosk.inventory.towerOneRemaining}</td>
                    </tr>
                    <tr>
                      <th className='left-align inventory-summary-header'>Tower 2</th>
                      <td className='center-align'>{state.kiosk.inventory.towerTwoRemaining}</td>
                    </tr>
                    <tr>
                      <th className='left-align inventory-summary-header'>Tower 3</th>
                      <td className='center-align'>{state.kiosk.inventory.towerThreeRemaining}</td>
                    </tr>
                  </table>
                </Card.Body>
              )}

              <Card.Body className='col s6 l3 m3'>
                <table style={{ tableLayout: 'fixed' }}>
                  <tr className='inventory-summary'>
                    <th className='left-align inventory-summary-header'>Caffeine</th>
                    <td className='center-align'>{state.kiosk.inventory.caffeine.toFixed(0)}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Lemonade V3</th>
                    <td className='center-align'>{state.kiosk.inventory.lemonadeV3?.toFixed(0)}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Blackberry</th>
                    <td className='center-align'>{state.kiosk.inventory.blackberry?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Mint Mojito</th>
                    <td className='center-align'>{state.kiosk.inventory.mintMojito?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Orange + Vitamin C</th>
                    <td className='center-align'>{state.kiosk.inventory.orange?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Tropical Energy</th>
                    <td className='center-align'>{state.kiosk.inventory.tropicalEnergy?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Passion Orange Guava</th>
                    <td className='center-align'>{state.kiosk.inventory.passionOrangeGuava?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Peach Tea</th>
                    <td className='center-align'>{state.kiosk.inventory.peachTea?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Pomegranate Acai</th>
                    <td className='center-align'>{state.kiosk.inventory.pomegranateAcai?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Raspberry Lychee</th>
                    <td className='center-align'>{state.kiosk.inventory.raspberryLychee?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Strawberry Kiwi</th>
                    <td className='center-align'>{state.kiosk.inventory.strawberryKiwi?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Tea + Lemonade</th>
                    <td className='center-align'>{state.kiosk.inventory.teaPlusLemonade?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Yuzu Ginger</th>
                    <td className='center-align'>{state.kiosk.inventory.yuzuGinger?.toFixed(0) || '-'}</td>
                  </tr>
                  <tr>
                    <th className='left-align inventory-summary-header'>Sanitizer</th>
                    <td className='center-align'>{state.kiosk.inventory.sanitizer?.toFixed(0) || '-'}</td>
                  </tr>
                </table>
              </Card.Body>

              <Card.Body className='col s12 l6 m6'>
                <table style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr>
                      <th />
                      <th className='center-align'>Water</th>
                      <th className='center-align'>Flavor</th>
                      <th className='center-align'>Supplement</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Drop Bottle</th>
                      <td className='center-align'>$ {(state.kiosk.drinkPrice.dropBottleWater / 100).toFixed(2)}</td>
                      <td className='center-align'>$ {(state.kiosk.drinkPrice.dropBottleFlavor / 100).toFixed(2)}</td>
                      <td className='center-align'>
                        $ {(state.kiosk.drinkPrice.dropBottleSupplement / 100).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>Refill</th>
                      <td className='center-align'>$ {(state.kiosk.drinkPrice.refillWater / 100).toFixed(2)}</td>
                      <td className='center-align'>$ {(state.kiosk.drinkPrice.refillFlavor / 100).toFixed(2)}</td>
                      <td className='center-align'>$ {(state.kiosk.drinkPrice.refillSupplement / 100).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card.Body>
          </Card>
          <KioskGraphs
            flavorGraph={state.kiosk.flavor_graph}
            caffeineFlavorGraph={state.kiosk.caffeine_flavor_graph}
            width={window.innerWidth}
          />
        </div>
      </div>
    )
  );
};

export default Kiosk;
