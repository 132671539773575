import { ReactElement, useState } from 'react';
import PeripheralIdentifier from '../../../../common/Peripherals/PeripheralIdentifier';
import GantryJog from './GantryControls/GantryControls';
import KioskEditStrings from '../../KioskEdit/KioskEditStrings';

interface IPeripheralControls {
  kioskId?: string;
}

const PeripheralControls: React.FC<IPeripheralControls> = ({ kioskId }) => {
  const [peripheralToDisplay, setPeripheralToDisplay] = useState<PeripheralIdentifier>();

  if (!kioskId) return null;

  const peripheralControlList: Partial<Record<PeripheralIdentifier, ReactElement>> = {
    [PeripheralIdentifier.Gantry]: (
      <GantryJog
        onCloseClick={() => setPeripheralToDisplay(undefined)}
        kioskId={kioskId}
        disable={peripheralToDisplay !== PeripheralIdentifier.Gantry}
      />
    )
  };

  const handleButtonClick = (identifier: PeripheralIdentifier) => {
    setPeripheralToDisplay(identifier);
  };

  return (
    <>
      <h4>{KioskEditStrings.PeripheralControlsHeading}</h4>
      {Object.entries(peripheralControlList).map(([identifier, component]) => {
        return (
          <ul>
            <li key={identifier}>
              <button onClick={() => handleButtonClick(+identifier)} disabled={false}>
                {PeripheralIdentifier[identifier]} {KioskEditStrings.PeripheralControlLabel}
              </button>
              {peripheralToDisplay === +identifier && component}
            </li>
          </ul>
        );
      })}
    </>
  );
};

export default PeripheralControls;
