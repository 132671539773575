import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import AuthGuard from '../../../../components/auth/AuthGuard';
import UserRole from '../../../../common/UserRole';
import { useAppSelector } from '../../../../hooks/storeHooks';
import useReports from '../../../../services/Reports/useReports';
import AnyDataTable from '../../../../components/table/AnyDataTable';
import IncidentReportData from '../IncidentReportData';
import IncidentReportHelper from '../IncidentReportHelper';
import IncidentReportStrings from '../IncidentReportStrings';
import IconLoading from '../../../../components/ui/Icons/IconLoading';
import IncidentReportHeader from './IncidentReportHeader';
import IncidentResolutionStatusSelect from '../IncidentResolutionStatusSelect';
import IncidentResolutionStatus from '../IncidentResolutionStatus';

interface IIncidentReport {}

const IncidentReport: React.FC<IIncidentReport> = () => {
  const initialState = {
    data: undefined,
    loading: true,
    error: ''
  };
  const [state, setState] = useState<{ data?: IncidentReportData; loading: boolean; error?: string }>(initialState);
  const user = useAppSelector((store) => store.auth?.user);
  const { getIncidentReport, updateIncidentResolution } = useReports();
  const params = useParams();

  const fetchReport = useCallback(
    async (correlationId: string) => {
      try {
        const report = await getIncidentReport(correlationId);
        setState((prevState) => ({
          ...prevState,
          data: report,
          error: undefined,
          loading: false
        }));
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          error: `${IncidentReportStrings.ErrorFetchingReports}: ${
            error instanceof Error ? error.message : JSON.stringify(error)
          }`,
          loading: false
        }));
      }
    },
    [getIncidentReport]
  );

  useEffect(() => {
    if (params.correlationId) fetchReport(params.correlationId);
  }, [params, getIncidentReport, fetchReport]);

  const handleResolutionChange = async (newResolution: IncidentResolutionStatus) => {
    try {
      if (state.loading) return;
      if (params.correlationId) {
        setState((prevState) => ({
          ...prevState,
          loading: true
        }));
        const update = await updateIncidentResolution(params.correlationId, newResolution);
        setState((prevState) => ({
          ...prevState,
          data: update,
          loading: false
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: `${IncidentReportStrings.ErrorUpdatingIncident}: ${
          error instanceof Error ? error.message : JSON.stringify(error)
        }`,
        loading: false
      }));
    }
  };

  if (!user?.role) return null;

  return (
    <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.role}>
      <div style={{ padding: '10px' }}>
        <IncidentReportHeader resolution={state.data?.resolution} />
        <div className='row'>
          <div className='col s12 m4' style={{ padding: 0 }}>
            {state.data?.resolution && (
              <IncidentResolutionStatusSelect value={state.data?.resolution} onChange={handleResolutionChange} />
            )}
          </div>
        </div>
        {state.loading && <IconLoading width={50} height={50} />}
        {state.error && <span style={{ color: 'red' }}>{state.error}</span>}
        {state.data &&
          IncidentReportHelper.getReportSections(state.data).map(({ title, dataPath }) => {
            const data = dataPath();
            if (!dataPath) return null;
            return (
              <div key={title}>
                <h5 style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>{title}</h5>
                <AnyDataTable data={data} />
              </div>
            );
          })}
      </div>
    </AuthGuard>
  );
};

export default IncidentReport;
