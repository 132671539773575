import { Card } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import propTypes from 'prop-types';

const KioskGraphs = ({ flavorGraph, caffeineFlavorGraph, width }) => {
  const _getPieChart = (chart_data) => {
    return (
      <Doughnut
        data={chart_data}
        height={width < 800 ? 280 : 150}
        options={{
          maintainAspectRatio: true,
          tooltips: {
            callbacks: {
              // https://stackoverflow.com/questions/43604597/how-to-customize-the-tooltip-of-a-chart-js-2-0-doughnut-chart
              // https://jsfiddle.net/m7s43hrs/
              //   title: function(tooltipItem, data) {
              // 	return data['labels'][tooltipItem[0]['index']];
              //   },
              //   label: function(tooltipItem, data) {
              // 	return data['datasets'][0]['data'][tooltipItem['index']];
              //   },
              afterLabel: function (tooltipItem, data) {
                var dataset = data['datasets'][0];
                var dataTmp = dataset['data'];

                var total = dataTmp.reduce((a, b) => a + b, 0); // get sum of data array
                var percent = Math.round((dataset['data'][tooltipItem['index']] / total) * 100);
                return '(' + percent + '%)';
              },
            },
            backgroundColor: '#000',
            titleFontSize: 15,
            titleFontColor: '#fff',
            bodyFontColor: '#fff',
            bodyFontSize: 15,
            displayColors: true,
          },
        }}
      />
    );
  };
  return (
    <>
      <Card style={{ borderRadius: '15px', width: '49.5%' }} className='col s12 l6 m6 center-align mr-2 pie-chart'>
        <Card.Body>
          <Card.Title style={{ fontWeight: '400' }}>Screen Servings by Flavor</Card.Title>
          <Card.Text style={{}} className=''>
            {_getPieChart(flavorGraph)}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card
        style={{ borderRadius: '15px', width: '49.5%', marginLeft: '1%' }}
        className='col s12 l6 m6 center-align ml-5 pie-chart'
      >
        <Card.Body className='center-align'>
          <Card.Title style={{ fontWeight: '400' }}>Caffeine Servings (Screen)</Card.Title>
          <Card.Text style={{ fontWeight: '400' }} className=''>
            {_getPieChart(caffeineFlavorGraph)}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

KioskGraphs.propTypes = {
  flavorGraph: propTypes.object.isRequired,
  caffeineFlavorGraph: propTypes.object.isRequired,
  width: propTypes.number.isRequired,
};

export default KioskGraphs;
