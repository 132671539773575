import { useContext } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { KioskSettingsContext } from './KioskSettingsContext';
import KioskStatus from '../../../../common/Kiosk/KioskStatus';

const KioskStatusSelect: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  const defaultKioskStatus = KioskStatus.Deployed;

  return (
    <>
      <TextField
        select
        type='select'
        size='small'
        variant='outlined'
        fullWidth={true}
        required={true}
        id='kioskStatus'
        label='Kiosk Status'
        value={kioskSettings?.state.settings?.kioskStatus ?? defaultKioskStatus}
        onChange={(e) => kioskSettings?.handleSettingsChange('kioskStatus', e.target.value as KioskStatus)}
        style={{ margin: '20px 0' }}
        disabled={!kioskSettings?.deviceStatus?.ready}
      >
        {Object.entries(KioskStatus).map(([key, value]) => (
          <MenuItem key={key} value={value}>
            {key}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default KioskStatusSelect;
