import React from 'react';
import ReactExport from 'react-export-excel';
import IInventory from '../../services/Inventory/IInventory';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IInventoryExcelExport {
  inventory: IInventory[];
  loadInventoryWithFilters: () => void;
}

const InventoryExcelExport: React.FC<IInventoryExcelExport> = ({ inventory, loadInventoryWithFilters }) => {
  if (!inventory || inventory.length === 0) return null;
  return (
    <ExcelFile
      element={
        <button style={{}} className='download-csv-button' onClick={loadInventoryWithFilters}>
          Export CSV
        </button>
      }
      filename={`DropWater Inventory ${new Date().toISOString().slice(0, 10)}`}
    >
      <ExcelSheet data={inventory} name='Kiosk Inventory'>
        <ExcelColumn label='Kiosk' value='kiosk_ID' />
        <ExcelColumn label='Active Tower' value='towerSelected' />
        <ExcelColumn label='Tower One' value='towerOneRemaining' />
        <ExcelColumn label='Tower Two' value='towerTwoRemaining' />
        <ExcelColumn label='Tower Three' value='towerThreeRemaining' />
        <ExcelColumn label='Caffeine (ml)' value='caffeine' />
        <ExcelColumn label='Lemonade V3 (ml)' value='lemonadeV3' />
        <ExcelColumn label='Tropical Energy (ml)' value='tropicalEnergy' />
        <ExcelColumn label='Blackberry (ml)' value='blackberry' />
        <ExcelColumn label='Mint Mojito (ml)' value='mintMojito' />
        <ExcelColumn label='Orange (ml)' value='orange' />
        <ExcelColumn label='Passion Orange Guava (ml)' value='passionOrangeGuava' />
        <ExcelColumn label='Peach Tea (ml)' value='peachTea' />
        <ExcelColumn label='Pomegranate Acai (ml)' value='pomegranateAcai' />
        <ExcelColumn label='Raspberry Lychee (ml)' value='raspberryLychee' />
        <ExcelColumn label='Strawberry Kiwi (ml)' value='strawberryKiwi' />
        <ExcelColumn label='Tea + Lemonade (ml)' value='teaPlusLemonade' />
        <ExcelColumn label='Yuzu Ginger (ml)' value='yuzuGinger' />
        <ExcelColumn label='Sanitizer (ml)' value='sanitizer' />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default InventoryExcelExport;
