import { MenuItem, TextField } from '@material-ui/core';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import IncidentResolutionStatus from './IncidentResolutionStatus';

interface IIncidentResolutionStatusSelect {
  value: IncidentResolutionStatus;
  onChange: (value: IncidentResolutionStatus) => void;
  disabled?: boolean;
}

const IncidentResolutionStatusSelect: React.FC<IIncidentResolutionStatusSelect> = ({ value, onChange, disabled }) => {
  return (
    <TextField
      select
      type='select'
      size='small'
      variant='outlined'
      fullWidth={true}
      required={true}
      id='resolution'
      label='Resolution Status'
      value={value}
      onChange={(e) => onChange(e.target.value as IncidentResolutionStatus)}
      style={{ margin: '20px 0' }}
      disabled={disabled}
    >
      {Object.values(IncidentResolutionStatus).map((option) => (
        <MenuItem key={option} value={option}>
          {kebabToTitleCase(option)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default IncidentResolutionStatusSelect;
