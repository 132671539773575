import React, { useContext } from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { KioskSettingsContext } from './KioskSettingsContext';
import PeripheralIdentifier from '../../../../common/Peripherals/PeripheralIdentifier';
import { AdditivesDevice } from '../../../../common/Peripherals/Additives/AdditivesMotion';
import { DeckMotionDevice } from '../../../../common/Peripherals/Deck/DeckMotion';
import { GantryDevice } from '../../../../common/Peripherals/Gantry/GantryMotion';
import { ReceptionMotionDevice } from '../../../../common/Peripherals/Reception/ReceptionMotion';
import { Card } from 'react-bootstrap';
import KioskEditStrings from '../KioskEditStrings';
import stringWithSpacesUppercase from '../../../../utils/stringWithSpacesUppercase';

const DisabledHardwareSelect: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const peripheralDeviceMapping = {
    [PeripheralIdentifier.Additives]: AdditivesDevice,
    [PeripheralIdentifier.Deck]: DeckMotionDevice,
    [PeripheralIdentifier.Gantry]: GantryDevice,
    [PeripheralIdentifier.Reception]: ReceptionMotionDevice,
  };

  const isDeviceDisabled = (device: number, devices?: number[]): boolean => {
    return devices?.includes(device) ?? false;
  };

  const handleCheckboxChange = (peripheralId: PeripheralIdentifier, device: number, isChecked: boolean) => {
    const currentDisabledHardware = kioskSettings?.state.settings?.disabledHardware || {};
    const disabledDevices = currentDisabledHardware[peripheralId] || [];

    if (isChecked) {
      if (!disabledDevices.includes(device)) {
        disabledDevices.push(device);
      }
    } else {
      const index = disabledDevices.indexOf(device);
      if (index !== -1) {
        disabledDevices.splice(index, 1);
      }
    }

    kioskSettings?.handleSettingsChange('disabledHardware', {
      ...currentDisabledHardware,
      [peripheralId]: disabledDevices,
    });
  };

  const renderPeripheralSection = (peripheralId: PeripheralIdentifier, devicesEnum) => {
    return (
      <div key={peripheralId}>
        <Card.Subtitle style={{ fontWeight: '700', fontSize: '14px' }}>
          {PeripheralIdentifier[peripheralId]}
        </Card.Subtitle>
        {Object.keys(devicesEnum)
          .filter((key) => isNaN(Number(key)))
          .map((deviceName) => {
            const deviceValue = devicesEnum[deviceName];
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDeviceDisabled(
                      deviceValue,
                      kioskSettings?.state?.settings?.disabledHardware?.[peripheralId]
                    )}
                    onChange={(e) => handleCheckboxChange(peripheralId, deviceValue, e.target.checked)}
                    name={`${peripheralId}-${deviceName}`}
                  />
                }
                label={`${stringWithSpacesUppercase(deviceName)}(${deviceValue})`}
                key={`${peripheralId}-${deviceName}`}
                style={{ color: '#333' }}
                disabled={!kioskSettings?.deviceStatus.ready}
              />
            );
          })}
      </div>
    );
  };

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.DisabledHardwareLabel}
      </Card.Subtitle>
      <p>
        <strong>NOTE:</strong> {KioskEditStrings.DisabledHardwareWarning}
      </p>
      <FormGroup>
        {Object.entries(peripheralDeviceMapping).map(([peripheralId, devicesEnum]) => {
          return renderPeripheralSection(peripheralId as unknown as PeripheralIdentifier, devicesEnum);
        })}
      </FormGroup>
    </>
  );
};

export default DisabledHardwareSelect;
