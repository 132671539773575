import { useCallback } from 'react';
import ReportsService from './ReportsService';
import IKioskHealthReport from './IKiosksHealthReport';
import IncidentReportData from '../../screens/Reports/IncidentReport/IncidentReportData';
import IncidentReportDataExcerpt from '../../screens/Reports/IncidentReport/IncidentReportDataExcerpt';
import IncidentResolutionStatus from '../../screens/Reports/IncidentReport/IncidentResolutionStatus';

const useReports = (): {
  getKiosksHealthReport: () => Promise<IKioskHealthReport[] | undefined>;
  getIncidentReport: (correlationId: string) => Promise<IncidentReportData | undefined>;
  getIncidentReports: () => Promise<IncidentReportDataExcerpt[] | undefined>;
  updateIncidentResolution: (
    correlationId: string,
    resolution: IncidentResolutionStatus
  ) => Promise<IncidentReportData | undefined>;
} => {
  const getKiosksHealthReport = useCallback(async () => {
    try {
      const report = await ReportsService.getKiosksHealthReport();
      return report;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getIncidentReport = useCallback(async (correlationId: string) => {
    try {
      const report = await ReportsService.getIncidentReport(correlationId);
      return report;
    } catch (err) {
      console.error(err);
      throw err
    }
  }, []);

  const getIncidentReports = useCallback(async () => {
    try {
      const report = await ReportsService.getIncidentReports();
      return report;
    } catch (err) {
      console.error(err);
      throw err
    }
  }, []);

  const updateIncidentResolution = useCallback(async (correlationId: string, resolution: IncidentResolutionStatus) => {
    try {
      const updatedReport = await ReportsService.updateIncidentReport(correlationId, { resolution });
      return updatedReport;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  return { getKiosksHealthReport, getIncidentReport, getIncidentReports, updateIncidentResolution };
};

export default useReports;
